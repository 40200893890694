import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AnalyzeImplicitBiasAnalysisInfo, ImplicitBiasResultDTO } from "../../../../external-api/service-api"
import { IApplicationState } from "src/state/store";
import { CountryPickerData, DateOfBirthPickerData, IImplicitBiasRequest, ImplicitBiasResults, LanguagePickerData, LikertData, MultipleChoiceData, ValidationErrors } from "src/type/globals";
import { enqueueNotification, resetHeaderVisibility } from "../ui/uiSlice";
import { refreshImplicitBiasResult } from "../user/userSlice";
import { ImplicitBiasResultModel } from "../../../../external-api/user-api";
import _ from "lodash";
import { navigate } from "@reach/router";
import { Paths } from "src/utilities/constants";
import { ImplicitBiasInterpretation, ImplicitBiasInterpretationDirection, TestResultDetailsDTO, TestResultDTO, TestSummaryDTO } from "external-api/license-api";
import { getImplicitBiasResults } from "src/services/analysisService";
import { addPublicTestResultsToUser, addTestResultsToUser } from "src/services/licenseService";
import { getPreviousResults } from "src/services/userService";
import { mapDirection, mapInterpretation } from "src/utilities/implicit-bias-utils";

import { v4 as uuidv4 } from 'uuid';
export interface SurveySectionData {
    name: string,
    type: string,
    submitted: boolean,
    data: DateOfBirthPickerData | MultipleChoiceData | LanguagePickerData | CountryPickerData | LikertData
}

interface ISurveyData {
    surveyId: string
    sections: { [key: string]: SurveySectionData }
}

export interface ISurveyState {
    activeModule: string
    implicitBiasResult: {
        id: string
        participantID: string
        implicitBiasInterpretation: number
        implicitBiasScore: number
    }
    summary: TestSummaryDTO
    surveyData: ISurveyData
}

const initialState: ISurveyState = {
    activeModule: "",
    surveyData: {
        surveyId: "",
        sections: {}
    },
    implicitBiasResult: {
        id: '',
        participantID: '',
        implicitBiasScore: 0,
        implicitBiasInterpretation: 0,
    },
    summary: {
        total: 0,
        averageScore: 0,
        interpretationItems: []
    }
}

const getAllImplicitBiasResultsAction = createAsyncThunk<
    undefined,
    string,
    {
        rejectValue: ValidationErrors,
        state: IApplicationState
    }>(
        '@@survey/getAllImplicitBiasResults',
        async (userId, thunkAPI) => {
            // -- Used for any extra dispatches or if u need to grab data from the current store state
            const { rejectWithValue, dispatch } = thunkAPI;
            try {
                const res: ImplicitBiasResultModel[] = await getPreviousResults(userId);


                const response = res.map<ImplicitBiasResults>(x => {
                    return {
                        surveyId: x.surveyId,
                        implicitBiasId: x.implicitBiasId,
                        userId: x.userId,
                        score: x.score,
                        preferenceIntensity: x.preferenceIntensity,
                        preferenceType: x.preferenceType,
                        completedDate: x.completedDate.toDateString()
                    }
                });

                dispatch(refreshImplicitBiasResult(response));
            }
            catch (err) {
                if (!err.status) {
                    throw err
                }
                dispatch(enqueueNotification({
                    message: 'Unable to fetch survey results',
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error'
                    }
                }));
                return rejectWithValue(err.body)
            }
        }
    );


const submitImplicitBiasAction = createAsyncThunk<
    undefined,
    IImplicitBiasRequest,
    {
        rejectValue: ValidationErrors,
        state: IApplicationState
    }>(
        '@@survey/submitImplicitBias',
        async (request, thunkAPI) => {
            // -- Used for any extra dispatches or if u need to grab data from the current store state
            const { rejectWithValue, dispatch, getState } = thunkAPI;
            try {
                const { surveyStore, userStore: { user, isFreeUser, isAnonymousUser, testInfo } } = getState();
                const requestData: AnalyzeImplicitBiasAnalysisInfo = {
                    rawInputs: request.rawInputs
                };

                const res: ImplicitBiasResultDTO = await getImplicitBiasResults(requestData);
                dispatch(setImplicitBiasResult(res));

                const testResultDetails: TestResultDetailsDTO = {
                    score: res.score,
                    highResponseTimeCount: res.highResponseTimeCount,
                    lowResponseTimePercentage: res.lowResponseTimePercentage,
                    practiceEffectSize: res.practiceEffectSize,
                    practiceMeanDiff: res.practiceMeanDiff,
                    practiceSTD: res.practiceSTD,
                    testEffectSize: res.testEffectSize,
                    testMeanDiff: res.testMeanDiff,
                    testSTD: res.testSTD
                };

                let direction = mapDirection(res.direction);
                let interpretation = mapInterpretation(res.interpretation);

                let saveImplicitBiasRequest: TestResultDTO = {
                    implicitBiasSurveyInputs: JSON.stringify(surveyStore.surveyData.sections),
                    rawInputs: request.rawInputs,
                    testId: request.surveyId,
                    interpretation: interpretation,
                    direction: direction,
                    testResultDetails: testResultDetails,
                    anonymousUser: isAnonymousUser,
                    groupId: testInfo.groupId,
                    scheduleId: testInfo.scheduleId
                };

                if (isFreeUser) {
                    const savePublicImplicitBiasResp: TestSummaryDTO = await addPublicTestResultsToUser(saveImplicitBiasRequest);
                    dispatch(setImplicitBiasSummary(savePublicImplicitBiasResp));
                }
                else {
                    if (isAnonymousUser) {
                        saveImplicitBiasRequest.participantId = uuidv4();
                    } else {
                        saveImplicitBiasRequest.participantId = user.publicID;
                    }
                    const saveImplicitBiasResp: TestSummaryDTO = await addTestResultsToUser(saveImplicitBiasRequest);
                    dispatch(setImplicitBiasSummary(saveImplicitBiasResp));
                }
                dispatch(resetHeaderVisibility());
                navigate(Paths.SURVEY_RESULTS);
            }
            catch (err) {
                if (!err.status) {
                    throw err
                }

                dispatch(enqueueNotification({
                    message: 'An error has Occurred',
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error'
                    }
                }));
                return rejectWithValue(err.body)
            }
        }
    );


const surveySlice = createSlice({
    name: "@@survey",
    initialState,
    reducers: {
        startSurveyAction(state, action) {
            state.surveyData.surveyId = action.payload;
            state.surveyData.sections = initialState.surveyData.sections;
        },
        setSurveyDataAction(state, action: PayloadAction<{ name: string, value: SurveySectionData }>) {
            const { name, value } = action.payload;
            _.set(state.surveyData.sections, `${name}`, value);
        },
        setActiveModule(state, action) {
            state.activeModule = action.payload
        },
        setImplicitBiasResult(state, action: PayloadAction<ImplicitBiasResultDTO>) {
            state.implicitBiasResult.implicitBiasInterpretation = action.payload.interpretation
            state.implicitBiasResult.implicitBiasScore = action.payload.score
        },
        setImplicitBiasSummary(state, action) {
            state.summary = action.payload
        }
    },
    extraReducers: () => { }
})

export const { setActiveModule, setImplicitBiasResult, startSurveyAction, setSurveyDataAction, setImplicitBiasSummary } = surveySlice.actions
export {
    getAllImplicitBiasResultsAction,
    submitImplicitBiasAction
}
export default surveySlice.reducer