import { ImplicitBiasInterpretation, ImplicitBiasInterpretationDirection } from "../../external-api/license-api";
import { ImplicitBiasResultDirection, ImplicitBiasResultInterpretation } from "../../external-api/service-api";
import { IQuestionType, ISection } from "src/type/globals";
import { ContentfulImplicitAssociationAssessment } from "types/graphql-types";
import { randomizeOptionList } from "./contentful-utils";

export function handleStartTimer(blockNum: number, trialNum: number) {
    performance.clearMarks();
    performance.clearMeasures();
    performance.mark(`start-${blockNum}-${trialNum}`);
}
export function handleEndTimer(blockNum: number, trialNum: number) {
    performance.mark(`end-${blockNum}-${trialNum}`);
    performance.measure(`duration-${blockNum}-${trialNum}`, `start-${blockNum}-${trialNum}`, `end-${blockNum}-${trialNum}`)
    const duration = performance.getEntriesByName(`duration-${blockNum}-${trialNum}`)[0];

    return duration.duration;
}

/**
 * Function that maps the data model from the CMS to the data model needed for the front end.
 */
export function ImplicitBiasConverter(data: ContentfulImplicitAssociationAssessment) {
    const {
        firstAssociationPair, secondAssociationPair
    } = data;

    let optionData: IQuestionType[] = [];

    [firstAssociationPair, secondAssociationPair].forEach(pair => {
        pair?.forEach(option => {
            const expectedAnswer = option?.text;
            const list = option?.list?.map(l => {
                return {
                    text: l?.text,
                    image: l?.image,
                    expectedAnswer: expectedAnswer,
                    id: l?.id,
                    type: l?.image ? 0 : 1
                } as IQuestionType
            });
            if (list) {
                optionData = optionData.concat(list);
            }
        });
    });

    const pairOnefirstCategory = firstAssociationPair && firstAssociationPair[0]?.text || "";
    const pairOneSecondCategory = firstAssociationPair && firstAssociationPair[1]?.text || "";

    const pairTwofirstCategory = secondAssociationPair && secondAssociationPair[0]?.text || "";
    const pairTwoSecondCategory = secondAssociationPair && secondAssociationPair[1]?.text || "";

    const sections: ISection[] = [
        {
            data: randomizeOptionList<IQuestionType>(optionData).slice(0, 20),
            leftAnswer: [pairOneSecondCategory, pairTwoSecondCategory],
            rightAnswer: [pairOnefirstCategory, pairTwofirstCategory]
        },
        {
            data: randomizeOptionList<IQuestionType>(optionData).slice(0, 20),
            leftAnswer: [pairOnefirstCategory, pairTwoSecondCategory],
            rightAnswer: [pairOneSecondCategory, pairTwofirstCategory]
        },
        {
            data: randomizeOptionList<IQuestionType>(optionData).slice(0, 20),
            leftAnswer: [pairOneSecondCategory, pairTwoSecondCategory],
            rightAnswer: [pairOnefirstCategory, pairTwofirstCategory]
        },
        {
            data: randomizeOptionList<IQuestionType>(optionData).slice(0, 20),
            leftAnswer: [pairOnefirstCategory, pairTwoSecondCategory],
            rightAnswer: [pairOneSecondCategory, pairTwofirstCategory]
        },
        {
            data: randomizeOptionList<IQuestionType>(optionData).slice(0, 20),
            leftAnswer: [pairOneSecondCategory, pairTwoSecondCategory],
            rightAnswer: [pairOnefirstCategory, pairTwofirstCategory]
        },
    ];

    return sections
}

export function mapDirection(direction: ImplicitBiasResultDirection) {
    let returnDir = ImplicitBiasInterpretationDirection.NUMBER_0;
    if (direction === 1) returnDir = ImplicitBiasInterpretationDirection.NUMBER_1;

    return returnDir;
}

export function mapInterpretation(interpretation: ImplicitBiasResultInterpretation) {
    let returnInt = ImplicitBiasInterpretation.NUMBER_0;

    if (interpretation === 1) returnInt = ImplicitBiasInterpretation.NUMBER_1;
    if (interpretation === 2) returnInt = ImplicitBiasInterpretation.NUMBER_2;
    if (interpretation === 3) returnInt = ImplicitBiasInterpretation.NUMBER_3;
    if (interpretation === 4) returnInt = ImplicitBiasInterpretation.NUMBER_4;

    return returnInt;
}
