
import { SatelliteTwoTone } from "@material-ui/icons";
import { createSelector } from "@reduxjs/toolkit";
import { IUiState } from "./uiSlice";

export const uiSelector = (state: { uiStore: IUiState }) => state.uiStore
export const getCourseState = (state: { uiStore: IUiState }) => state.uiStore.courseStarted;
export const getButtonState = (state: { uiStore: IUiState }) => state.uiStore.buttonState;

const _getCourseStarted = (state: { uiStore: IUiState }) => state.uiStore.courseStarted;
const _getSurveyStarted = (state: { uiStore: IUiState }) => state.uiStore.surveyStarted;
const _getNotifications = (state:{uiStore: IUiState})=> state.uiStore.notifications;


export const getNotifications = createSelector(
    [_getNotifications],
    (notifications: any[]) => {
        return notifications;
    }
)


export const getHeaderVisibility = createSelector(
    [_getCourseStarted, _getSurveyStarted],
    (courseStarted: boolean, surveyStarted: boolean) => {
        return courseStarted || surveyStarted
    }
)



